import React from 'react'
import teamBanner from '../assets/team-banner.jpg'
import umeshHari from '../assets/umeshHari.jpg'
import shrivathsShyam from '../assets/shrivathsShyam.jpg'
import mageshVaidheeswaran from '../assets/magesh-vaidheeswaran.jpg'
import { Col, Container, Row } from 'react-bootstrap'
import '../styles/team/team.scss'

function Team() {
  return (
    <div className='team-componenet'>
      <div className='image-main'>
        <img className='about-img' src={teamBanner} alt='teamBanner' />
        <div className='overlay'></div>
        <p className='content'>Our Team</p>
      </div>
      <div>
        <Container className='container'>
          <Row className='row'>
            <Col className='img-main' xl={4} lg={5} md={6} sm={12} xs={12} xxs={12}>
              <img className={'image-team'} src={umeshHari} alt='umeshHari' />
            </Col>
            <Col className='content-main' xl={5} lg={6} md={6} sm={12} xs={12} xxs={12}>
              <p className='name'>UMESH HARI</p>
              <p className='position'>Co-Founder and CEO</p>
              <p className='content'>
                Mr. Umesh Hari is senior partner and data strategist experienced in formulating and delivering enterprise data transformation for global financial institutions in the US, Europe, and Latin America. He has more than 25 years of experience across IBM, Accenture and Deloitte as a partner in strategizing, architecting, and implementing AI infused data platforms on prem, cloud and hybrid data ecosystems in the areas of Corporate Risk, Regulatory Compliance and Finance, Retail & Mortgage Banking, Insurance, Asset & Wealth Management, Corporate Banking & Securities, Credit Cards, Energy Trading, Oil & Gas, Utilities,  Manufacturing and ESG.. He has extensive experience in aligning business leadership imperatives with the strategic direction and execution of large-scale data programs that include, data governance, data quality, master data, big data, data lakes, Lakehouse, analytics, AI/ Gen AI, business intelligence, data stores, portals, data warehousing, data management, enterprise architecture, data integration, and data architectures. His past clients globally include Citibank, HSBC, JPMC, Wells Fargo, Broadridge, American Express, Fannie Mae, OP Bank, Westpac, Travellers, Atradius, Principal, ExxonMobil, NY Power Authority, ConocoPhillips and Florida Power & Light.
              </p>
            </Col>
          </Row>
          <Row className='row' style={{ backgroundColor: "#e0f1f1", width: "100%" }} >
            <Col className='img-main' xl={4} lg={5} md={6} sm={12} xs={12} xxs={12}>
              <img className={'image-team'} src={mageshVaidheeswaran} alt='mageshVaidheeswaran' />
            </Col>
            <Col className='content-main' xl={5} lg={6} md={6} sm={12} xs={12} xxs={12}>
              <p className='name'>MAGESH VAIDHEESWARAN</p>
              <p className='position'>Co-Founder and COO</p>
              <p className='content'>
                Magesh Vaidheeswaran is a seasoned Partner and strategist with 25+ years of consulting experience across Strategy, Data & Analytics, and Enterprise Applications. He has created client value through numerous transformational engagements across Deloitte, Accenture, and IBM and across North America (US, Canada), Europe (The Netherlands, France, Spain, and Italy), and Asia (India, and The Philippines). He has worked with numerous client CxOs and senior executives to leverage data as a strategic asset for growth and transformation, leading implementation of modern data platforms on cloud and on-prem, Master Data Management, Data Governance, AI & Gen-AI, Data Quality, Data Lakehouses, and Data Architecture. His experience spans Retail, Financial Services (Financial markets, Insurance, Credit card Intermediaries), Energy & Utilities, Electronics/Hi-tech, Industrial (Chemicals, Manufacturing), Healthcare, and Life Sciences. A few of his key global clients are Dun & Bradstreet, Roche Diagnostics, Sempra, Ross Storers, Nationwide, Broadridge Financial, Citibank, Nestle, Santander, Nokia, Ashland, Aera Energy, Rohm & Haas, and Valley Health.
              </p>
            </Col>
          </Row>
          <Row className='row' >
            <Col className='img-main' xl={4} lg={5} md={6} sm={12} xs={12} xxs={12}>
              <img className={'image-team'} src={shrivathsShyam} alt='shrivathsShyam' />
            </Col>
            <Col className='content-main' xl={5} lg={6} md={6} sm={12} xs={12} xxs={12}>
              <p className='name'>SHRIVATHS SHYAM</p>
              <p className='position'>AI Analyst</p>
              <p className='content'>
                Shrivaths Shyam is a master's graduate from UC San Diego with expertise in machine learning, software engineering and data science. As a research assistant at the Salk Institute, he has worked with object detection, pose estimation and deep learning with additional experience in training computer vision, Gen AI models and LLMs. Before this, he worked at Cognizant for two years, building projects for a Fortune 500 client in the insurance industry using BPM and DevOps tools. Overall, he has experience building software applications, especially with full-stack development, data science, analytics and deep learning.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}

export default Team